import $ from 'jquery';

const blockTags = new Set();
let tagCounter = 0;

function nextTag() {
    // Any unique identifier is fine
    return tagCounter++;
}

export function blockFadeIn() {
    const tag = nextTag();
    blockTags.add(tag);
    return tag;
}

export function unblockFadeIn(tag) {
    blockTags.delete(tag);
    tryFadeIn();
}

function tryFadeIn() {
    if (blockTags.size > 0) {
        return;
    }

    $('#main-container').css('opacity', 1);
    $('.navbar .container').css('opacity', 1);
    $('.footer').css('opacity', 1);

    // const animationDuration = 250;
    // $('#main-container').animate({ opacity: 1 }, animationDuration);
    // $('.navbar .container').animate({ opacity: 1 }, animationDuration);
    // $('.footer').animate({ opacity: 1 }, animationDuration);
}

const mainPageBlock = blockFadeIn();

$(function() {
    unblockFadeIn(mainPageBlock);
});
